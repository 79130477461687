import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   // base: process.env.BASE_URL,
   routes: [
      {
         path: '/',
         name: '',
         component: () => import(/* webpackChunkName: "Home" */ './Dash.vue'),
         children: [
            {
               path: '/',
               name: 'home',
               component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue'),
               meta: { title: 'home', canonical: '/home' }
            },
            {
               path: '/blog',
               name: 'Blog',
               component: () => import(/* webpackChunkName: "Blog" */ './views/blog/Blog.vue'),
               meta: { title: 'Blog', canonical: '/blog' }
            },
            {
               path: '/blog/:blog_id',
               name: 'BlogArtigo',
               component: () => import(/* webpackChunkName: "BlogArtigo" */ './views/blog/Blog.vue'),
               meta: { title: 'BlogArtigo', canonical: '/blog/:blog_id' }
            },
            {
               path: '/profissionais',
               name: 'Profissionais',
               component: () => import(/* webpackChunkName: "Profissionais" */ './views/profissionais/Profissional.vue'),
               meta: { title: 'Profissionais', canonical: '/profissionais' }
            },
            {
               path: '/tratamento',
               name: 'Tratamento',
               component: () => import(/* webpackChunkName: "Tratamento" */ './views/tratamento/Tratamento.vue'),
               meta: { title: 'Tratamento', canonical: '/tratamento' }
            },
            {
               path: '/tratamento/:tratamento_id',
               name: 'TratamentoArtigo',
               component: () => import(/* webpackChunkName: "Tratamento" */ './views/tratamento/Tratamento.vue'),
               meta: { title: 'TratamentoArtigo', canonical: '/tratamento/:tratamento_id' }
            },
            {
               path: '/convenio',
               name: 'Convenio',
               component: () => import(/* webpackChunkName: "Convenio" */ './views/convenio/Convenio.vue'),
               meta: { title: 'Convenio', canonical: '/convenio' }
            },
            {
               path: '/contato',
               name: 'Contato',
               component: () => import(/* webpackChunkName: "Contato" */ './views/contato/Contato.vue'),
               meta: { title: 'Contato', canonical: '/contato' }
            },
            {
               path: '/local_atendimento',
               name: 'LocalAtendimento',
               component: () => import(/* webpackChunkName: "LocalAtendimento" */ './views/LocalAtendimento/LocalAtendimento.vue'),
               meta: { title: 'LocalAtendimento', canonical: '/local_atendimento' }
            },
            {
               path: '/doppler',
               name: 'Doppler',
               component: () => import(/* webpackChunkName: "Doppler" */ './views/doppler/DopperVasculares.vue'),
               meta: { title: 'Doppler', canonical: '/doppler' }
            },
            {
               path: '/:pathMatch(.*)*',
               name: 'not-found',
               component: () => import(/* webpackChunkName: "404" */ './views/NotFound/NotFound.vue'),
               meta: { title: 'Página não encontrada', canonical: '/not-found' }
            }
         ]
      }
   ]
})


router.beforeEach((to, from, next) => {
   document.title = to.meta && to.meta.title ? to.meta.title + ' - Vida Vascular' : 'Vida Vascular';
   next();
});

router.afterEach((to) => {
   const linkCanonical = document.querySelector("link[rel='canonical']");
   const canonicalUrl = window.location.origin + ( window.location.pathname||to.meta?.canonical);


   if (linkCanonical) {
      linkCanonical.setAttribute("href", canonicalUrl);
   } else {
      const link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      link.setAttribute("href", canonicalUrl);
      document.head.appendChild(link);
   }
});

export default router
